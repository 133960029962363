<template>
  <div class="resumeForm">
    <b-form @submit="onSubmit" v-if="show">
      <!---------------------->
      <b-form-group
        id="input-group-firstname"
        label="Your First Name:"
        label-for="input-firstname"
      >
        <b-form-input
          id="input-firstname"
          v-model="userInfo.firstname"
          placeholder="First name"
          required
        ></b-form-input>
      </b-form-group>
      <!---------------------->
      <b-form-group
        id="input-group-last"
        label="Your Last Name:"
        label-for="input-lastname"
      >
        <b-form-input
          id="input-lastname"
          v-model="userInfo.lastname"
          placeholder="Last name"
          required
        ></b-form-input>
      </b-form-group>
      <!---------------------->
      <b-form-group
        id="input-group-1"
        label="Email address:"
        label-for="input-1"
        type="email"
        description="This will apear on your resume."
      >
        <b-form-input
          id="input-1"
          v-model="userInfo.email"
          placeholder="Enter email"
          required
        ></b-form-input>
      </b-form-group>
      <!---------------------->
      <b-form-group
        id="input-group-phone"
        label="Phone"
        label-for="input-1"
        type="phone"
        description="This will apear on your resume."
      >
        <b-form-input
          id="input-1"
          v-model="userInfo.phone"
          placeholder="Phone#"
        ></b-form-input>
      </b-form-group>
      <!---------------------->
      <b-form-group
        id="input-group-address"
        label="Address on resume"
        label-for="input-address"
        description="This will apear on your resume."
      >
        <b-form-input
          id="input-address"
          v-model="userInfo.address"
          placeholder="i.e: City, State"
        ></b-form-input>
      </b-form-group>
      <!---------------------->
      <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="form.checked"
          id="checkboxes-4"
          :aria-describedby="ariaDescribedby"
        >
          <b-form-checkbox value="save_profile"
            >Save to my profile for the future resumes</b-form-checkbox
          >
        </b-form-checkbox-group>
      </b-form-group>
      <!---------------------->
      <b-button
        v-on:click="submit_form(true, $event)"
        id="bt_save"
        value="save"
        variant="outline-primary"
        class="submit_bt"
        >Save</b-button
      >
    </b-form>
    <!-- <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ userInfo }}</pre>
    </b-card> -->
  </div>
</template>

<script>
import axios from "axios";
const token = "secret_hachalhaft_token-1";
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
export default {
  name: "Profile",
  components: {},
  props: {
    userInfo: {
      firstname: String,
      lastname: String,
      phone: String,
      email: String,
      address: String,
    },
  },
  data() {
    return {
      form: {
        checked: [],
      },

      show: true,
    };
  },
  methods: {
    onSubmit(event) {
      //console.log("submit-type:" + this.form.submit.value);
      event.preventDefault();
      alert(JSON.stringify(this.form));
      this.$router.push("experinces");
    },
    // onReset(event) {
    //   event.preventDefault();
    //   // Reset our form values

    //   this.userInfo.firstname = "";
    //   this.userInfo.lastname = "";
    //   this.userInfo.phone = "";
    //   this.userInfo.email = "";
    //   this.userInfo.address = "";
    //   // Trick to reset/clear native browser form validation state
    //   this.show = false;
    //   this.$nextTick(() => {
    //     this.show = true;
    //   });
    // },
    submit_form: function (redirect, event) {
      event.preventDefault();
      //let jsonData = {};

      this.userInfo.auth0_token = this.$auth.user.sub;
      //jsonData = JSON.parse(JSON.stringify(this.userInfo));
      this.$api.updateUserProfile(this.userInfo);
      this.$store.commit("updateUserInfo", this.userInfo);
      if (redirect) {
        this.$router.push("/");
      }
    },
  },
};
</script>
<style scoped>
.resumeForm {
  text-align: left;
}
.submit_bt {
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}
</style>
