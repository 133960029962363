<template lang="">
  <div>
    <personal-info-form :userInfo="userInfo" />
  </div>
</template>
<script>
import Profile from "../components/forms/FormProfile.vue";
import axios from "axios";
const token = "secret_hachalhaft_token-1";
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
export default {
  components: {
    "personal-info-form": Profile,
  },
  data() {
    return {
      userInfo: {
        firstname: this.$store.state.user.firstname,
        lastname: this.$store.state.user.lastname,
        phone: this.$store.state.user.phone,
        email: this.$store.state.user.email,
        address: this.$store.state.user.address,
      },
    };
  },

  mounted() {
    //console.log("onMounted - " + this.$auth.isAuthenticate);
    if (this.$auth.isAuthenticated) {
      this.$api.fetchUserProfile(this.$auth.user.sub);
    }
  },
};
</script>
<style lang=""></style>
